import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Button, Grid } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardImage from 'components/EventOrGroupCard/CardImage';
import GetImageUrl from 'helpers/GetImage';
import moment from 'moment';
import { useNavigate } from 'react-router';
import '../EventOrGroupCard/index.scss';
import { Interweave } from 'interweave';
import useUser from 'hooks/useUser';
import './index.scss';
import useHttpGet from 'hooks/useHttpGet';
import { useEffect } from 'react';
import { isAuthorizedEvent } from 'helpers/AuthorizedPage';
import LoadingIcon from 'components/LoadingIcon';

export interface CardInterface {
  eventId: number;
  title: string;
  description: string;
  deadline: moment.Moment;
  startAttendance: moment.Moment;
  imageUrl: string;
  groupName: string;
  address: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
}

function TextHelper(
  text: string,
  format: {
    className?: string;
    fontSize?: number;
    fontWeight?: number;
    color?: string;
    lineHeight?: number;
    paddingBottom?: number;
  }
) {
  return <Typography {...format}>{text}</Typography>;
}

function EventCard(props: CardInterface) {
  const {
    eventId,
    title,
    description,
    imageUrl,
    groupName,
    address,
    startTime,
    endTime,
  } = props;
  const { isAuthenticated, authorizedEvents, email } = useUser();
  const navigate = useNavigate();
  const {
    data: event,
    isLoading: isLoadingEvent,
    error,
    reload: reloadEvent,
  } = useHttpGet<any | undefined>(`/events/${eventId}/overview`);

  const reloadData = () => {
    reloadEvent();
  };

  useEffect(() => {
    if (error) navigate('/page-not-found');
  }, [error]);

  useEffect(() => {
    if (event === undefined) return;
    if (!authorizedEvents.length && isAuthenticated) return;
    if (
      isAuthorizedEvent(eventId ?? '', authorizedEvents) ||
      event?.isPublished ||
      event.creatorEmail === email
    )
      return;
    navigate('/');
  }, [event, authorizedEvents]);

  if (isLoadingEvent) {
    return <LoadingIcon />;
  }
  return (
    <>
      <div onClick={() => navigate('/events/' + eventId)}>
        <CardImage
          imageUrl={eventId === -1 ? imageUrl : GetImageUrl(imageUrl)}
          title={title}
          year={startTime.format('YYYY')}
          isEvent={true}
        />
        <CardContent>
          <Grid container direction="row" marginBottom="10px">
            <Box
              sx={{
                width: '18%',
                paddingRight: '4%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {TextHelper(startTime.format('D'), {
                fontSize: 30,
                fontWeight: 600,
                lineHeight: 1,
              })}
              {TextHelper(startTime.format('MMM'), {
                fontSize: 17,
                fontWeight: 600,
              })}
            </Box>
            <Box
              sx={{
                width: '70%',
                paddingLeft: '4%',
                borderLeft: '1.5px solid black',

                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {TextHelper(groupName, { fontWeight: 600, color: 'primary' })}
              <Box className="center-content" height="20px">
                <AccessTimeIcon className="icon-size" />
                {TextHelper(
                  `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`,
                  {
                    fontSize: 14,
                  }
                )}
              </Box>
              <Box className="center-content" height="30px">
                <LocationOnIcon className="icon-size" />
                <Typography fontSize={14} lineHeight={1}>
                  {address}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Typography
            className="description-field"
            variant="body1"
            height={75}
            sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
          >
            <Interweave content={description} />
          </Typography>
        </CardContent>
      </div>

      <Box
        sx={{
          display: 'inline',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '10px',
          left: '10px',
          right: '10px',
          width: '100% - 20px',
          whiteSpace: 'nowrap',
        }}
      >
        <Button
          disableElevation
          disabled={eventId === -1 ? true : false}
          color="primary"
          variant="outlined"
          onClick={() => navigate('/events/' + eventId)}
          sx={{
            float: 'left',
            width: '100%',
          }}
        >
          Learn more
        </Button>
      </Box>
    </>
  );
}

export default EventCard;
