import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';

interface EmailFormProps {
  open: boolean;
  onClose: () => void;
  onSend: (subject: string, body: string, replyTo: string) => void;
  senderEmail: string;
}

const EmailForm: React.FC<EmailFormProps> = ({
  open,
  onClose,
  onSend,
  senderEmail,
}) => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [replyTo, setReplyTo] = useState(senderEmail);

  const handleSend = () => {
    onSend(subject, body, replyTo);
    setSubject('');
    setBody('');
    setReplyTo(senderEmail);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <TextField
          margin="dense"
          label="Reply-To Email"
          type="email"
          fullWidth
          value={replyTo}
          onChange={(e) => setReplyTo(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Subject"
          type="text"
          fullWidth
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Body"
          multiline
          rows={4}
          fullWidth
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSend} color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailForm;
