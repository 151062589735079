import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';

export default function useHttpGet<TData = any>(
  url: string,
  config?: AxiosRequestConfig
) {
  const [error, setError] = useState<Error>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<TData>();

  const httpGet = async (
    config?: Partial<AxiosRequestConfig>
  ): Promise<void> => {
    try {
      setLoading(true);
      const response = await axiosInstance.get<TData>(url, {
        ...config,
      });

      setData(response.data);
      setError(undefined);
    } catch (err) {
      if (axios.isCancel(err)) return;
      setData(undefined);
      setLoading(false);
      setError(err as Error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    httpGet({ ...config, signal: controller.signal });
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return { data, error, isLoading, reload: httpGet };
}
