import axiosInstance from 'api/axiosInstance';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useState } from 'react';

interface HttpSendArgs {
  url: string;
  method: 'post' | 'put';
  config?: Partial<AxiosRequestConfig>;
}

function useHttpWithData<TPayload = any>({
  url,
  method,
  config,
}: HttpSendArgs) {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number>();
  const [data, setData] = useState<any>();

  const httpSend = async (payload: TPayload) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance[method](url, payload, {
        ...config,
      });
      setData(response?.data);
      setStatus(response?.status);
      setError(undefined);
    } catch (error) {
      if (axios.isCancel(error)) return;
      setStatus((error as AxiosError)!.response!.status);
      setError(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return { error, status, data, isLoading, send: httpSend };
}

export default useHttpWithData;
