import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { currentUserState } from 'atoms';
import axiosInstance from 'api/axiosInstance';

const useLoadUser = () => {
  const {
    isLoading: isLoadingAuth0,
    user: userAuth0,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();

  const [, setCurrentUser] = useRecoilState(currentUserState);

  const loadCurrentUser = useCallback(async () => {
    if (!isAuthenticated || isLoadingAuth0) {
      setCurrentUser((prev) => ({ ...prev, isLoading: false }));
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently();
      if (!accessToken) {
        return;
      }

      const res = await axiosInstance.get<any>('/users/me');

      const userData = res.data;
      const user = userData.user;
      const membershipIDs = user.memberships.map((m: any) => m.socialGroupId);

      setCurrentUser((prev) => ({
        ...prev,
        ...userData,
        ...user,
        userId: user.appUserId,
        location: user.location?.city.toLowerCase() ?? '',
        memberships: membershipIDs,
        isLoading: false,
      }));
    } catch (error) {
      console.error('Error loading user:', error);
    }
    setCurrentUser((prev) => ({ ...prev, isLoading: false }));
  }, [isAuthenticated, isLoadingAuth0, getAccessTokenSilently, setCurrentUser]);

  const setAuth0Data = useCallback(async () => {
    if (!userAuth0) return;
    const roles: string[] = userAuth0['https://capsocial.com/roles'];
    setCurrentUser((prev) => {
      return {
        ...prev,
        isAuthenticated,
        isCapgemini: roles.some((r) => r === 'Cap'),
        isCoreMember: roles.some((r) => r === 'CoreMember'),
        isAdmin: roles.some((r) => r === 'Admin'),
        isCoreMemberOrAdmin: roles.some(
          (r) => r === 'Admin' || r === 'CoreMember'
        ),
      };
    });
  }, [userAuth0, isAuthenticated, setCurrentUser]);

  useEffect(() => {
    if (!isLoadingAuth0 && isAuthenticated) {
      setAuth0Data();
    }
  }, [isLoadingAuth0, isAuthenticated, setAuth0Data]);

  useEffect(() => {
    if (!isLoadingAuth0) {
      loadCurrentUser();
    }
  }, [isAuthenticated, isLoadingAuth0, loadCurrentUser]);

  const reloadUser = () => {
    setAuth0Data();
    loadCurrentUser();
  };

  return {
    isAuthenticated,
    isLoadingAuth0,
    reloadUser,
  };
};

export default useLoadUser;
