import { apiBaseUrl } from './common';
import axios from 'axios';

const axiosHttp = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// Adds access tokens in all API requests
export const addAccessTokenInterceptor = (
  isAuthenticated: boolean,
  getAccessTokenSilently: () => Promise<string>,
  loginWithRedirect: () => void,
  logout: () => void
) => {
  axiosHttp.interceptors.request.use(
    async (config) => {
      if (!isAuthenticated) {
        return config;
      }
      try {
        const token = await getAccessTokenSilently();

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          // This branch may not be hit since an error is usually thrown when there's no token
          console.warn('Authenticated, but Access token is missing.');
          loginWithRedirect();
        }
        return config;
      } catch (error: any) {
        console.error('Error fetching access token:', error);

        if (error.error === 'login_required') {
          console.warn('Login required, redirecting...');
          loginWithRedirect();
        } else {
          console.warn('Error fetching token, logging out...');
          logout();
        }
        return Promise.reject(error);
      }
    },
    (error) => {
      // Handle any request errors
      console.error('Error with the request:', error);
      return Promise.reject(error);
    }
  );
};

export default axiosHttp;
